export const StyleControl = function({
  callback, styles,
}) {
  this.styles = styles
  this.callback = callback
}

StyleControl.prototype = {
  active: 0,
  /**
   * MapBox API
   */
  onAdd(map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl StyleToggle'
    this._container.classList.add(`StyleToggle--${this.styles[1].class}`)
    this._container.textContent = ''
    this._container.addEventListener('click', this.handleClick.bind(this))
    return this._container
  },
  onRemove() {
    this._container.removeEventListener('click', this.handleClick)
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  },
  getDefaultPosition() {
    return 'bottom-right'
  },
  /**
   * Additional methods
   */
  handleClick: function (e) {
    if (this.active === 0) {
      if (this.styles[1].url) {
        this._map.setStyle(this.styles[1].url)
      }
      this._container.classList.remove(`StyleToggle--${this.styles[1].class}`)
      this._container.classList.add(`StyleToggle--${this.styles[0].class}`)
      this.active = 1
    } else {
      if (this.styles[0].url) {
        this._map.setStyle(this.styles[0].url)
      }
      this._container.classList.remove(`StyleToggle--${this.styles[0].class}`)
      this._container.classList.add(`StyleToggle--${this.styles[1].class}`)
      this.active = 0
    }
    if (this.callback) {
      this.callback(e, this)
    }
  },
}
