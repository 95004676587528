import Vue from 'vue'
import Vuex from 'vuex'


import config from './config'
import app from './app'
import map from './map'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    config,
    app,
    map,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
})
