<template>
  <div />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Bugfender } from '@bugfender/sdk'

import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import pointsWithinPolygon from '@turf/points-within-polygon'
import { point } from '@turf/helpers'

const turf = {
  pointsWithinPolygon,
  booleanPointInPolygon,
  point,
}

export default {
  name: 'ChargerPointsLayer',
  data() {
    return {
      requiredIcons: [
        'alert',
        'charging-hub-definitive-private',
        'charging-hub-definitive-semipublic',
        'charging-hub-definitive',
        'charging-hub-disabled-private',
        'charging-hub-disabled-semipublic',
        'charging-hub-disabled',
        'charging-hub-in-progress-private',
        'charging-hub-in-progress-proprietary',
        'charging-hub-in-progress-semipublic',
        'charging-hub-in-progress',
        'charging-hub-realized-private',
        'charging-hub-realized-proprietary',
        'charging-hub-realized-semipublic',
        'charging-hub-realized',
        'charging-hub-rejected-private',
        'charging-hub-rejected-semipublic',
        'charging-hub-rejected',
        'charging-hub-request-private',
        // 'charging-hub-request-semipublic',
        'charging-hub-request',
        'charging-hub-suggestion-private',
        'charging-hub-suggestion-proprietary',
        'charging-hub-suggestion-semipublic',
        'charging-hub-suggestion',
        'definitive-private',
        'definitive-semipublic',
        'definitive',
        'disabled-private',
        'disabled-semipublic',
        'disabled',
        'fastcharger-definitive-private',
        'fastcharger-definitive-semipublic',
        'fastcharger-definitive',
        'fastcharger-disabled-private',
        // 'fastcharger-disabled-semipublic',
        'fastcharger-disabled',
        'fastcharger-in-progress-private',
        'fastcharger-in-progress-semipublic',
        'fastcharger-in-progress',
        'fastcharger-realized-private',
        'fastcharger-realized-semipublic',
        'fastcharger-realized',
        'fastcharger-rejected-private',
        'fastcharger-rejected-semipublic',
        'fastcharger-rejected',
        'fastcharger-request-private',
        // 'fastcharger-request-semipublic',
        'fastcharger-request',
        'fastcharger-suggestion-private',
        'fastcharger-suggestion-semipublic',
        'fastcharger-suggestion',
        'in-progress-private',
        'in-progress-semipublic',
        'in-progress',
        'realized-private',
        'realized-semipublic',
        'realized',
        'rejected-private',
        'rejected-semipublic',
        'rejected',
        'request-private',
        // 'request-semipublic',
        'request',
        'suggestion-private',
        'suggestion-semipublic',
        'suggestion',
      ],
      iconCounter: 0,
    }
  },
  computed: {
    ...mapGetters('config', [
      'participationConfig',
    ]),
    ...mapGetters('map', [
      'isLoaded',
      'getLocations',
    ]),
    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },
  },
  watch: {
    participationConfig: 'setup',
    isLoaded: {
      immediate: true,
      handler (loaded) {
        if (loaded) {
          this.setup()
        }
      },
    },
    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer()
      }
    },
    getLocations(locations) {
      let source = this.$store.map?.getSource('chargingpoints')
      if (source) {
        source.setData(this.generateGeoJson(locations))
      }
    },
  },
  methods: {
    ...mapActions('map', [
      'getChargingLocations',
    ]),
    setup() {
      if (this.participationConfig && this.isLoaded && !this.active) {
        this.active = true
        try {
          this.getChargingLocations({ previewCode: this.$route.query.preview })
        } catch {
          Bugfender.warn('The charging points could not be fetched')
        }
        this.loadIcons()
      } else if (this.isLoaded) {
        this.loadIcons()
      } else {
        this.iconCounter = 0
        this.removeLayer()
      }
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.iconCounter = 0

      this.requiredIcons.forEach(name => {
        if (! this.$store.map) return
        if (this.$store.map.hasImage(name)) return

        this.$store.map.loadImage(
          require(`@/assets/image/chargingpoint/png/${name}.png`),
          (err, image) => {
            if (err) throw err

            this.$store.map.addImage(name, image)
            this.iconCounter++
        })
      })
    },

    /**
     * Translate datapoints into GeoJSON
     */
    generateGeoJson(points) {
      let geoJson = {
        'type': 'FeatureCollection',
        'features': [],
      }

      points.forEach(point => {
        geoJson.features.push({
          'type': 'Feature',
          'properties': {
            ...point.properties,
            refId: point.ref['@ref'].id,
          },
          'geometry': {
            'type': 'Point',
            'coordinates': point.coordinates,
          },
        })
      })

      // If there is a geography polygon defined, only return the points that are within the geography polygon
      if (this.participationConfig?.geography) {
        const geography = JSON.parse(this.participationConfig.geography)
        return turf.pointsWithinPolygon(geoJson, geography)
      }

      return geoJson
    },

    removeLayer() {
      ['chargingpoints', 'chargingpoints-text'].forEach(name => {
        if (this.$store.map?.getLayer(name)) {
          this.$store.map.removeLayer(name)
        }
        if (this.$store.map?.getSource(name)) {
          this.$store.map.removeSource(name)
        }
      })
    },

    /**
     *
     */
    addLayer() {
      let source = this.$store.map.getSource('chargingpoints')
      if (source) {
        this.$store.map.removeLayer('chargingpoints')
        this.$store.map.removeLayer('chargingpoints-text')
        this.$store.map.removeSource('chargingpoints')
      }

      this.$store.map.addSource('chargingpoints', {
        type: 'geojson',
        data: this.generateGeoJson(this.getLocations),
      })

      this.$store.map.addLayer({
        'id': 'chargingpoints',
        'type': 'symbol',
        'source': 'chargingpoints',
        'minzoom': 10,
        'layout': {
          'symbol-sort-key': 1,
          'symbol-placement': 'point',
          'symbol-z-order': 'source',
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          'icon-image': [
            'match',
            ['get', 'status'],
            ...this.requiredIcons.reduce((total, icon) => [...total, icon, icon], []),
            'nodefaultimage', // Hide suggestions
          ],
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0.05,
            18,
            0.2,
          ],
          'text-allow-overlap': true,
          'text-field': [
            'match',
            ['get', 'status'],
            'definitive', ['get', 'id'],
            'in-progress', ['get', 'id'],
            '', // Hide suggestions
          ],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold',
          ],
          'text-size': 12,
          // 'text-offset': [-1.9, -0.6],
          'text-radial-offset': 0.75,
          'text-variable-anchor': ['top-right', 'bottom-right', 'bottom-left', 'top-left', 'right', 'left', 'top', 'bottom'],
          'text-justify': 'auto',
        },
        'paint': {
          // "icon-color": "",
          'icon-opacity': [ // transition based on zoom
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0,
            10.2,
            0.8,
          ],
          'text-opacity': 0,
        },
      })

      /**
       * Add the ID next to definitive charging points
       */
      this.$store.map.addLayer({
        'id': 'chargingpoints-text',
        'type': 'symbol',
        'source': 'chargingpoints',
        'minzoom': 10,
        'layout': {
          'symbol-sort-key': 1,
          'symbol-placement': 'point',
          'symbol-z-order': 'source',
          // 'text-allow-overlap': true,
          'text-field': [
            'match',
            ['get', 'status'],
            'definitive', ['get', 'id'],
            // "in-progress", ['get', 'id'],
            '', // Hide suggestions
          ],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold',
          ],
          'text-size': 12,
          // 'text-offset': [-1.9, -0.6],
          'text-radial-offset': 0.75,
          'text-variable-anchor': ['top-right', 'bottom-right', 'bottom-left', 'top-left', 'right', 'left', 'top', 'bottom'],
          'text-justify': 'auto',
        },
        'paint': {
          'text-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            14,
            0,
            14.2,
            0.8,
          ],
        },
      })
    },
  },
}

</script>
