<template>
  <nav class="NavBar d-flex flex-row">
    <div class="NavBar__logo">
      <Logo
        organisation="EVtools"
        image="evtools.png"
      />
    </div>
    <p class="NavBar__description align-self-center my-0 flex-grow-1">
      <span
        class="ml-2 d-flex"
        v-text="title"
      />
    </p>
    <div
      v-if="participationConfig"
      class="NavBar__buttons"
    >
      <b-button
        v-if="participationConfig.interactionEnabled && participationConfig.suggestionsEnabled"
        :variant="isSuggestionModeActive ? 'warning' : 'light'"
        @click="toggleSuggestionModeState"
      >
        <BIconGeoAlt
          v-if="!isSuggestionModeActive"
          class="pt-1"
        />
        <span v-text="isSuggestionModeActive ? $t('suggestionButton.stopSuggesting') : $t('suggestionButton.startSuggesting')" />
      </b-button>
      <b-dropdown
        variant="primary"
        right
        no-caret
      >
        <template #button-content>
          <div class="d-flex">
            <span
              class="NavBar__menu__text"
              v-text="$t('navbar.menuName')"
            />
            <BIconList scale="1.4" />
          </div>
        </template>
        <b-dropdown-item @click="togglePolicyModal">
          {{ $t('navbar.showPolicy') }}
        </b-dropdown-item>
        <b-dropdown-item @click="toggleIntroductionModal">
          {{ $t('navbar.showIntroductionModal') }}
        </b-dropdown-item>
        <b-dropdown-item @click="downloadMap">
          {{ $t('navbar.downloadMap') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="participationConfig.translationsEnabled || inDevelopmentMode"
          @click="changeLocale"
        >
          {{ toggleLanguageText }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item disabled>
          V{{ version }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </nav>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import Logo from '@/components/Logo'
import { generateExport } from '@/services/pdfexport.js'

import { mapGetters, mapMutations, mapActions } from 'vuex'
import { BIconList, BIconGeoAlt } from 'bootstrap-vue'

export default {
  name: 'NavBar',
  components: {
    Logo,
    BIconList,
    BIconGeoAlt,
  },
  computed: {
    ...mapGetters('app', ['version', 'inDevelopmentMode']),
    ...mapGetters('config', ['participationConfig', 'isPreview', 'isInteractive']),
    ...mapGetters('map', ['isSuggestionModeActive']),
    title() {
      if (this.isPreview) {
        const openDate = dayjs(this.participationConfig.openDate).locale(this.$i18n.locale).format('dddd D MMMM')
        return this.$t(`navbar.${this.isInteractive ? 'title': 'viewerTitle'}Preview`, { openDate })
      }

      return this.$t(`navbar.${this.isInteractive ? 'title': 'viewer'}`)
    },
    toggleLanguageText() {
      return this.$i18n.locale === 'nl' ? '🇬🇧 English' : '🇳🇱 Nederlands'
    },
  },
  methods: {
    ...mapMutations('app', [
        'toggleIntroductionModal',
        'togglePolicyModal',
    ]),
    ...mapActions('map', ['toggleSuggestionModeState']),
    changeLocale() {
      const newLocale = this.$i18n.locale === 'nl' ? 'en' : 'nl'
      localStorage.setItem('locale', newLocale)
      this.$i18n.locale = newLocale
    },
    downloadMap() {
      generateExport({
        data: {
          title: this.$t('downloadMap.title', { municipality: this.participationConfig.municipalityName }),
          map: this.$store.map.getCanvas().toDataURL('image/png'),
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.NavBar {
  position: relative;
  height: 70px;
  min-height: 70px;
  background: map_get($theme-colors, "primary");
  box-shadow: 0px -1px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;

  &__logo {
    height: 100%;
    padding: 4px;

    @media (max-width: 1050px) {
      width: 200px;
    }
  }

  &__buttons {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.5rem;

    @media (min-width: 600px) {
      margin-right: 1rem;

      .b-dropdown {
        margin-left: 3rem;
      }
    }
  }

  &__menu__text {
    margin-right: 1rem;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__description {
    height: 1.1rem;
    font-size: 1.1rem;
    color: map_get($theme-colors, "light");

    @media (max-width: 1200px) {
      span {
        display: none;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }
  }
}
</style>
