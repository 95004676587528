<template>
  <form @submit.stop.prevent="handleComment">
    <b-alert
      :show="!!errorMessage"
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>
    <div
      v-if="!isNewSuggestion"
      class="sentimentButtons"
    >
      <label>
        <input
          :checked="feedback.sentiment === 1"
          :disabled="commentHasBeenSaved"
          type="radio"
          name="sentiment"
          @change="selected => feedback.sentiment = selected ? 1 : 0"
        >
        <div class="sentimentButton">
          <BIconHandThumbsUp scale="1.1" />
        </div>
      </label>
      <label>
        <input
          :checked="feedback.sentiment === -1"
          :disabled="commentHasBeenSaved"
          type="radio"
          name="sentiment"
          @change="selected => feedback.sentiment = selected ? -1 : 0"
        >
        <div class="sentimentButton">
          <BIconHandThumbsDown scale="1.1" />
        </div>
      </label>
    </div>
    <b-form-textarea
      id="commentTextArea"
      v-model.trim="feedback.message"
      :disabled="commentHasBeenSaved"
      :required="feedback.sentiment === -1"
      :placeholder="$t('actionSidebar.form.feedbackPlaceholder')"
      rows="4"
      max-rows="10"
    />
    <div ref="friendlyCaptcha" />
    <b-button
      type="submit"
      :variant="commentHasBeenSaved ? 'success' : 'primary'"
      class="submitButton"
      block
      :disabled="isSendingComment || !captchaSolution || (!isNewSuggestion && !feedback.sentiment) || commentHasBeenSaved"
    >
      {{ $t(`actionSidebar.form.submitButton.${submitButtonText}`) }}
    </b-button>
  </form>
</template>

<script>
import { WidgetInstance } from 'friendly-challenge'
import { mapGetters, mapMutations } from 'vuex'
import { BIconHandThumbsUp, BIconHandThumbsDown } from 'bootstrap-vue'
import { Bugfender } from '@bugfender/sdk'

import { checkStatus, returnJson } from '@/helpers/api'

export default {
  name: 'ActionSidebarForm',
  components: {
    BIconHandThumbsUp,
    BIconHandThumbsDown,
  },
  data () {
    return {
      feedback: {
        sentiment: 0,
        message: '',
      },
      captchaInstance: null,
      captchaSolution: null,
      isSendingComment: false,
      commentHasBeenSaved: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters('map', ['getSuggestedChargingpoint', 'getSelectedChargingpoint']),
    ...mapGetters('config', ['participationConfig']),
    isNewSuggestion() {
      return !this.chargingpoint.uuid
    },
    submitButtonText() {
      if (this.isSendingComment) {
        return 'saving'
      }

      if (this.isNewSuggestion) {
        return this.commentHasBeenSaved ? 'locationSaved' : 'saveLocation'
      }

      return this.commentHasBeenSaved ? 'commentSaved' : 'saveComment'
    },
    chargingpoint() {
      return this.getSuggestedChargingpoint ?? this.getSelectedChargingpoint
    },
  },
  watch: {
    chargingpoint() {
      if (this.commentHasBeenSaved) {
        this.feedback = {
          sentiment: 0,
          message: '',
        }
      }
      this.commentHasBeenSaved = false
      this.captchaSolution = null
      this.captchaInstance?.reset()
    },
  },
  mounted () {
    const element = this.$refs.friendlyCaptcha

    if (!element) return

    this.captchaInstance = new WidgetInstance(element, {
      sitekey: process.env.VUE_APP_FRIENDLY_CAPTCHA_SITE_KEY,
      language: this.$i18n.locale,
      doneCallback: solution => this.captchaSolution = solution,
    })
  },
  methods: {
    ...mapMutations('map', [
      'addChargingLocation',
    ]),
    async handleComment() {
      this.isSendingComment = true

      try {
        if (this.isNewSuggestion) {
          const { chargingpoint } = await this.saveNewSuggestion()
          localStorage.setItem(`has-commented-on-${chargingpoint.data.uuid}`, true)
          this.addChargingLocation({ location: chargingpoint })
        } else {
          await this.saveComment()
          localStorage.setItem(`has-commented-on-${this.chargingpoint.uuid}`, true)
        }
        this.commentHasBeenSaved = true
      } catch (e) {
        this.errorMessage = e.message === 'The puzzle that the solution was for has expired or has already been used'
          ? this.$t('actionSidebar.form.expiredCaptcha')
          : e.message
      } finally {
        this.isSendingComment = false
      }
    },
    async saveComment() {
      return await fetch('/.netlify/functions/commentsave', {
        method: 'POST',
        body: JSON.stringify({
          participationUuid: this.participationConfig.uuid,
          previewCode: this.$route.query.preview,
          chargingpointUuid: this.chargingpoint.uuid,
          feedback: this.feedback,
          solution: this.captchaSolution,
        }),
      })
      .then(await checkStatus)
      .then(returnJson)
      .catch(e => {
        Bugfender.error('saveComment: ', e)
        throw e
      })
    },
    async saveNewSuggestion() {
      return await fetch('/.netlify/functions/suggestlocation', {
        method: 'POST',
        body: JSON.stringify({
          participationUuid: this.participationConfig.uuid,
          previewCode: this.$route.query.preview,
          coordinates: this.chargingpoint.coordinates,
          feedback: this.feedback,
          solution: this.captchaSolution,
        }),
      })
      .then(await checkStatus)
      .then(returnJson)
      .catch(e => {
        Bugfender.error('suggestLocation: ', e)
        throw e
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/config";

.sentimentButtons {
  display: flex;

  label {
    .sentimentButton {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -1rem;
      margin-bottom: 0.5rem;
      border-radius: 50%;
      background-color: map_get($theme-colors, "light");
      border: none;
      line-height: 1.2rem;
      font-size: 1.2rem;
      user-select: none;
    }

    &:first-child {
      margin-right: 1rem;
    }

    input {
      width: 0;
      height: 0;
      opacity: 0; // Required for Firefox

      &:not([disabled="disabled"]) + .sentimentButton:hover {
        cursor: pointer;
        background-color: darken(map_get($theme-colors, "light"), 5%);
      }

      &:checked + .sentimentButton {
        background-color: map_get($theme-colors, "primary");
        color: white;
      }

      &:checked:not([disabled="disabled"]) + .sentimentButton:hover {
        background-color: darken(map_get($theme-colors, "primary"), 10%);
      }
    }
  }
}
</style>
