<template>
  <div
    v-if="participationConfig"
    class="w-100"
  >
    <MapBox
      :access-token="accessToken"
      :map-style.sync="mapStyle"
      :options="options"
      @load="onMapLoaded"
    >
      <ChargerDetailsPopup />
      <ChargerSuggestionPopup />
    </MapBox>
    <MapLegend v-if="isMapInteractive" />
    <MunicipalityLogo
      v-if="isMapInteractive && showLogo"
      :logo-height="logoHeight"
    />
    <Sidebar v-if="isMapInteractive" />
  </div>
</template>

<script>
// /*global arguments */

import MapBox from '@/components/map/MapBox'
import ChargerDetailsPopup from '@/components/map/modals/ChargerDetailsPopup'
import ChargerSuggestionPopup from '@/components/map/modals/ChargerSuggestionPopup'
import MapLegend from '@/components/map/MapLegend'
import MunicipalityLogo from '@/components/map/MunicipalityLogo'
import Sidebar from '@/components/map/sidebar/Sidebar'

import { mapGetters, mapMutations } from 'vuex'
import { StyleControl } from '@/services/mapbox'

import mapboxgl from 'mapbox-gl'

// Mapbox GeoCoder
require('es6-promise').polyfill() // IE 11 polyfill
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

export default {
  components: {
    MapBox,
    ChargerSuggestionPopup,
    ChargerDetailsPopup,
    MapLegend,
    MunicipalityLogo,
    Sidebar,
  },
  data() {
    return {
      loaded: false,
      customLogoHeights: {
        '0273': '10rem',
        '0432': '8em',
      },
      showNoLogoMunicipalities: [
        'almere',
      ],
    }
  },
  computed: {
    ...mapGetters('config', [
      'accessToken',
      'mapStyle',
      'mapStyleSat',
      'participationConfig',
      'participationIsClosed',
    ]),
    showLogo () {
      return !this.showNoLogoMunicipalities.includes(this.participationConfig.slug)
    },
    isMapInteractive() {
      return this.loaded && !this.participationIsClosed
    },
    options() {
      return {
        interactive: !this.participationIsClosed,
        bounds: this.participationConfig.bounds,
        fitBoundsOptions: {
          padding: 20,
        },
      }
    },
    logoHeight () {
      const code = this.participationConfig.municipalityCode
      return this.customLogoHeights[code] || ''
    },
  },
  beforeDestroy() {
    this.setLoadedStatus({
      status: false,
    })
  },
  methods: {
    ...mapMutations('map', [
      'setLoadedStatus',
    ]),
    onMapLoaded({ map }) {

      // Store the map in a global, non-reactive manner
      this.$store.map = map

      this.addMapControls()

      this.loaded = true

      this.setLoadedStatus({
        status: true,
      })
    },
    addMapControls() {
      if (this.participationIsClosed) return

      // Create the GeoCoder plugin on non-mobile screens
      if (!window.innerWidth || window.innerWidth > 600) {
        const geocoder = new MapboxGeocoder({
          accessToken: this.accessToken,
          mapboxgl: mapboxgl,
          countries: 'nl',
          placeholder: this.$t('map.search'),
        })

        geocoder.setBbox(this.participationConfig.bounds)
        this.$store.map.addControl(geocoder, 'top-left')
      }

      // adds zoom control to the map
      this.$store.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        }),
        'top-left',
      )

      // Add geolocate control to the map.
      this.$store.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        'top-left',
      )

      this.$store.map.addControl(
        new mapboxgl.ScaleControl(),
        'bottom-left',
      )

      this.$store.map.addControl(
        new StyleControl({
          styles: [{
            class: 'Light',
            url: this.mapStyle,
          }, {
            class: 'Satellite',
            url: this.mapStyleSat,
          }],
        }),
        'bottom-left',
      )
      // When the style changes, trigger a reload of all layers
      this.$store.map.on('style.load', this.reloadLayers)
    },

    /**
     * When the map style changes, all layers need to be reloaded
     */
    reloadLayers() {
      this.setLoadedStatus({ status: false })

      this.$nextTick(() => {
        this.setLoadedStatus({ status: true })
        this.addAerialPhotography()
      })
    },
    /**
     * Add aerial photography
     *  WMTS layers cannot be included in mapbox styles. They need to be added with js
     */
    addAerialPhotography() {
      if (this.loaded) {
        let { sprite } = this.$store.map.getStyle()

        if (!sprite) return

        // If it's the SAT style that is active
        if (sprite.replace('sprites', 'styles').startsWith(this.mapStyleSat)) {
          this.$store.map.addSource('aerial-photography', {
            'type': 'raster',
            'tiles': [
              'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?bbox={bbox-epsg-3857}&styles=&format=image/jpeg&service=WMS&version=2.0&request=GetMap&crs=EPSG:3857&transparent=true&width=256&height=256&layers=Actueel_ortho25',
            ],
            'tileSize': 256,
          })
          this.$store.map.addLayer({
              'id': 'aerial-photography-layer',
              'type': 'raster',
              'source': 'aerial-photography',
              'minzoom': 0,
              'maxzoom': 22,
            }, 'tunnel-street-minor-low',
          )
        }
      }
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-canvas-container, .mapboxgl-canvas {
  &:active, &:focus {
    outline: none;
  }
}

.mapboxgl-marker {
  position: absolute;
  cursor: pointer;
}


.StyleToggle {
  display: block;
  width: 64px;
  height: 64px;
  background: transparent;
  cursor: pointer;
  border: 1px solid black;
  background-size: 200%;

  &--Light {
    background-image: url('~@/assets/image/style-light.png')
  }
  &--Satellite {
    background-image: url('~@/assets/image/style-satellite.png')
  }
}
</style>
