<template>
  <div
    v-if="visibleLayersCount"
    class="LegendWrapper"
  >
    <b-button
      :pressed.sync="isOpen"
      text="Legenda"
      variant="primary"
    >
      {{ isOpen ? $t('legend.closeLegend') : $t('legend.openLegend') }}
    </b-button>
    <div
      v-if="isOpen"
      class="Legend"
    >
      <ul class="m-0 p-0 mb-3 list-unstyled">
        <li
          v-if="visibleLayersCount > 1"
          class="d-flex ml-3 pl-2 my-3 align-items-center"
        >
          <span
            class="ml-2 flex-grow-1"
            v-text="$t('legend.allLayers')"
          />
          <ToggleSwitch
            v-model="all"
            class="m-0"
          />
        </li>

        <li
          v-for="layer in filteredLayers"
          :key="layer.id"
          class="d-flex my-3 align-items-center"
        >
          <span>
            <img
              :src="layer.image"
              :width="layer.imageWidth"
            >
          </span>
          <span
            class="ml-2 mr-3 flex-grow-1"
            v-text="$t(`chargingpoint.status.${layer.label}`)"
          />
          <ToggleSwitch
            v-model="layer.visible"
            class="m-0"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/form/ToggleSwitch'

import { image } from '@/helpers/assets'
import { mapGetters } from 'vuex'

export default {
  name: 'MapLegend',
  components: {
    ToggleSwitch,
  },
  data() {
    return {
      isOpen: !window.innerWidth || window.innerWidth > 600,
      layers: [
        {
          id: 'alert',
          label: 'alert',
          image: image({ name: 'chargingpoint/svg/alert.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'suggestion',
          label: 'suggestion',
          image: image({ name: 'chargingpoint/svg/suggestion.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'suggestion-semipublic',
          label: 'suggestion-semipublic',
          image: image({ name: 'chargingpoint/svg/suggestion-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'suggestion-private',
          label: 'suggestion-private',
          image: image({ name: 'chargingpoint/svg/suggestion-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-suggestion',
          label: 'fastcharger-suggestion',
          image: image({ name: 'chargingpoint/svg/fastcharger-suggestion.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-suggestion-semipublic',
          label: 'fastcharger-suggestion-semipublic',
          image: image({ name: 'chargingpoint/svg/fastcharger-suggestion-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-suggestion-private',
          label: 'fastcharger-suggestion-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-suggestion-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-suggestion',
          label: 'charging-hub-suggestion',
          image: image({ name: 'chargingpoint/svg/charging-hub-suggestion.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-suggestion-proprietary',
          label: 'charging-hub-suggestion-proprietary',
          image: image({ name: 'chargingpoint/svg/charging-hub-suggestion-proprietary.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-suggestion-semipublic',
          label: 'charging-hub-suggestion-semipublic',
          image: image({ name: 'chargingpoint/svg/charging-hub-suggestion-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-suggestion-private',
          label: 'charging-hub-suggestion-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-suggestion-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'definitive',
          label: 'definitive',
          image: image({ name: 'chargingpoint/svg/definitive.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'definitive-semipublic',
          label: 'definitive-semipublic',
          image: image({ name: 'chargingpoint/svg/definitive-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'definitive-private',
          label: 'definitive-private',
          image: image({ name: 'chargingpoint/svg/definitive-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-definitive',
          label: 'fastcharger-definitive',
          image: image({ name: 'chargingpoint/svg/fastcharger-definitive.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-definitive-semipublic',
          label: 'fastcharger-definitive-semipublic',
          image: image({ name: 'chargingpoint/svg/fastcharger-definitive-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-definitive-private',
          label: 'fastcharger-definitive-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-definitive-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-definitive',
          label: 'charging-hub-definitive',
          image: image({ name: 'chargingpoint/svg/charging-hub-definitive.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-definitive-semipublic',
          label: 'charging-hub-definitive-semipublic',
          image: image({ name: 'chargingpoint/svg/charging-hub-definitive-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-definitive-private',
          label: 'charging-hub-definitive-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-definitive-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-disabled-private',
          label: 'charging-hub-disabled-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-disabled-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-disabled-semipublic',
          label: 'charging-hub-disabled-semipublic',
          image: image({ name: 'chargingpoint/svg/charging-hub-disabled-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-disabled',
          label: 'charging-hub-disabled',
          image: image({ name: 'chargingpoint/svg/charging-hub-disabled.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'in-progress',
          label: 'in-progress',
          image: image({ name: 'chargingpoint/svg/in-progress.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'in-progress-semipublic',
          label: 'in-progress-semipublic',
          image: image({ name: 'chargingpoint/svg/in-progress-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'in-progress-private',
          label: 'in-progress-private',
          image: image({ name: 'chargingpoint/svg/in-progress-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-in-progress',
          label: 'fastcharger-in-progress',
          image: image({ name: 'chargingpoint/svg/fastcharger-in-progress.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-in-progress-semipublic',
          label: 'fastcharger-in-progress-semipublic',
          image: image({ name: 'chargingpoint/svg/fastcharger-in-progress-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-in-progress-private',
          label: 'fastcharger-in-progress-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-in-progress-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-in-progress',
          label: 'charging-hub-in-progress',
          image: image({ name: 'chargingpoint/svg/charging-hub-in-progress.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-in-progress-proprietary',
          label: 'charging-hub-in-progress-proprietary',
          image: image({ name: 'chargingpoint/svg/charging-hub-in-progress-proprietary.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-in-progress-semipublic',
          label: 'charging-hub-in-progress-semipublic',
          image: image({ name: 'chargingpoint/svg/charging-hub-in-progress-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-in-progress-private',
          label: 'charging-hub-in-progress-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-in-progress-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'realized',
          label: 'realized',
          image: image({ name: 'chargingpoint/svg/realized.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'realized-semipublic',
          label: 'realized-semipublic',
          image: image({ name: 'chargingpoint/svg/realized-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'realized-private',
          label: 'realized-private',
          image: image({ name: 'chargingpoint/svg/realized-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-realized',
          label: 'fastcharger-realized',
          image: image({ name: 'chargingpoint/svg/fastcharger-realized.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-realized-semipublic',
          label: 'fastcharger-realized-semipublic',
          image: image({ name: 'chargingpoint/svg/fastcharger-realized-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-realized-private',
          label: 'fastcharger-realized-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-realized-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-realized',
          label: 'charging-hub-realized',
          image: image({ name: 'chargingpoint/svg/charging-hub-realized.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-realized-semipublic',
          label: 'charging-hub-realized-semipublic',
          image: image({ name: 'chargingpoint/svg/charging-hub-realized-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-realized-proprietary',
          label: 'charging-hub-realized-proprietary',
          image: image({ name: 'chargingpoint/svg/charging-hub-realized-proprietary.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-realized-private',
          label: 'charging-hub-realized-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-realized-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-rejected-private',
          label: 'charging-hub-rejected-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-rejected-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-rejected-semipublic',
          label: 'charging-hub-rejected-semipublic',
          image: image({ name: 'chargingpoint/svg/charging-hub-rejected-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-rejected',
          label: 'charging-hub-rejected',
          image: image({ name: 'chargingpoint/svg/charging-hub-rejected.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'charging-hub-request-private',
          label: 'charging-hub-request-private',
          image: image({ name: 'chargingpoint/svg/charging-hub-request-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        // {
        //   id: 'charging-hub-request-semipublic',
        //   label: 'charging-hub-request-semipublic',
        //   image: image({ name: 'chargingpoint/svg/charging-hub-request-semipublic.svg' }),
        //   imageWidth: 20,
        //   visible: true
        // },
        {
          id: 'charging-hub-request',
          label: 'charging-hub-request',
          image: image({ name: 'chargingpoint/svg/charging-hub-request.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'disabled-private',
          label: 'disabled-private',
          image: image({ name: 'chargingpoint/svg/disabled-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'disabled-semipublic',
          label: 'disabled-semipublic',
          image: image({ name: 'chargingpoint/svg/disabled-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'disabled',
          label: 'disabled',
          image: image({ name: 'chargingpoint/svg/disabled.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-disabled-private',
          label: 'fastcharger-disabled-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-disabled-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        // {
        //   id: 'fastcharger-disabled-semipublic',
        //   label: 'fastcharger-disabled-semipublic',
        //   image: image({ name: 'chargingpoint/svg/fastcharger-disabled-semipublic.svg' }),
        //   imageWidth: 20,
        //   visible: true
        // },
        {
          id: 'fastcharger-disabled',
          label: 'fastcharger-disabled',
          image: image({ name: 'chargingpoint/svg/fastcharger-disabled.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-rejected-private',
          label: 'fastcharger-rejected-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-rejected-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-rejected-semipublic',
          label: 'fastcharger-rejected-semipublic',
          image: image({ name: 'chargingpoint/svg/fastcharger-rejected-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-rejected',
          label: 'fastcharger-rejected',
          image: image({ name: 'chargingpoint/svg/fastcharger-rejected.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'fastcharger-request-private',
          label: 'fastcharger-request-private',
          image: image({ name: 'chargingpoint/svg/fastcharger-request-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        // {
        //   id: 'fastcharger-request-semipublic',
        //   label: 'fastcharger-request-semipublic',
        //   image: image({ name: 'chargingpoint/svg/fastcharger-request-semipublic.svg' }),
        //   imageWidth: 20,
        //   visible: true
        // },
        {
          id: 'fastcharger-request',
          label: 'fastcharger-request',
          image: image({ name: 'chargingpoint/svg/fastcharger-request.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'rejected-private',
          label: 'rejected-private',
          image: image({ name: 'chargingpoint/svg/rejected-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'rejected-semipublic',
          label: 'rejected-semipublic',
          image: image({ name: 'chargingpoint/svg/rejected-semipublic.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'rejected',
          label: 'rejected',
          image: image({ name: 'chargingpoint/svg/rejected.svg' }),
          imageWidth: 20,
          visible: true,
        },
        {
          id: 'request-private',
          label: 'request-private',
          image: image({ name: 'chargingpoint/svg/request-private.svg' }),
          imageWidth: 20,
          visible: true,
        },
        // {
        //   id: 'request-semipublic',
        //   label: 'request-semipublic',
        //   image: image({ name: 'chargingpoint/svg/request-semipublic.svg' }),
        //   imageWidth: 20,
        //   visible: true
        // },
        {
          id: 'request',
          label: 'request',
          image: image({ name: 'chargingpoint/svg/request.svg' }),
          imageWidth: 20,
          visible: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('map', ['isLoaded', 'hasSuggestions']),
    ...mapGetters('config', ['participationConfig']),
    visibleLayersCount() {
      return this.participationConfig?.statuses?.length ?? 0
    },
    // Toggle all layers
    all: {
      get() {
        return this.layers.every(layer => layer.visible)
      },
      set(isVisible) {
        this.layers.forEach(layer => layer.visible = isVisible)
      },
    },
    filteredLayers() {
      return this.layers
        .filter(({ id }) => {
          // Show the layer if its included in the statuses
          if (this.participationConfig?.statuses?.includes(id)) {
            return true
          }

          // Show the suggestion layer if interaction is enabled
          const suggestionsEnabled =
            this.participationConfig.interactionEnabled &&
            this.participationConfig.suggestionsEnabled

          return id === 'suggestion' && (
            suggestionsEnabled || this.hasSuggestions
          )
        })
        .map(layer => {
          // Change the label for suggestions if interaction is enabled
          if (layer.id.startsWith('suggestion') && this.participationConfig.interactionEnabled) {
            layer.label = layer.label.replace('suggestion', 'participation-suggestion')
          }

          return layer
        })
    },
  },
  watch: {
    // Handle toggling of layers
    layers: {
      handler: 'toggleLayers',
      deep: true,
    },
  },
  mounted() {
    // Set the correct layers when switching to/from satellite view
    this.$store.map?.on('styledata', this.toggleLayers)
  },
  beforeDestroy() {
    this.$store.map.off('styledata', this.toggleLayers)
  },
  created() {
    this.setDefaultVisibility()
  },
  methods: {
    setDefaultVisibility() {
      if (this.participationConfig.defaultLayerVisibility) {
        this.layers = this.layers.map(layer => {
          return {
            ...layer,
            visible: this.participationConfig.defaultLayerVisibility[layer.id] ?? true,
          }
        })
      }
    },
    toggleLayers() {
      if (!this.isLoaded) return

      const visibleState = 0.8
      const invisibleState = 0

      const state = ['match', // visibility state based filter
          ['get', 'status'],
          ...this.layers.reduce((total, layer) => [...total, layer.id, layer.visible ? visibleState : invisibleState], []),
          visibleState]

      const iconState = [ // transition based on zoom
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        0,
        10.2,
        state,
      ]

      const textState = [ // transition based on zoom
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        0,
        14.2,
        state,
      ]

      if (this.$store.map?.getLayer('chargingpoints')) {
        this.$store.map.setPaintProperty('chargingpoints', 'icon-opacity', iconState)
      }
      if (this.$store.map?.getLayer('chargingpoints-text')) {
        this.$store.map.setPaintProperty('chargingpoints-text', 'text-opacity', textState)
      }
    },
  },
}
</script>

<style lang="scss">
.LegendWrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0.7rem;
  padding-right: 0.7rem;

  .Legend {
    margin-top: 0.3rem;
    background-color: white;
    padding: 0.3rem 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid lightgray;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1));

    overflow-x: hidden;
    max-height: 465px;
  }
}
</style>
