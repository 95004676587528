import dayjs from 'dayjs'
import { checkStatus, returnJson } from '@/helpers/api'
import { Bugfender } from '@bugfender/sdk'

const state = {
  accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
  mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
  mapStyleSat: process.env.VUE_APP_MAPBOX_STYLE_SAT,
  isLoadingConfig: false,
  participationConfigs: null,
  participationConfig: null,
}

const getters = {
  accessToken: state => state.accessToken,
  mapStyle: state => state.mapStyle,
  mapStyleSat: state => state.mapStyleSat,
  isLoadingConfig: state => state.isLoadingConfig,
  participationConfigs: state => state.participationConfigs,
  participationConfig: state => state.participationConfig,
  isInteractive: state => state.participationConfig?.interactionEnabled,
  isInteractiveOnPastParticipations: state => state.participationConfig?.interactionOnPastParticipations,
  isPreview: (state) => {
    if (!state.participationConfig?.openDate) {
      return false
    }

    return dayjs().isBefore(state.participationConfig.openDate)
  },
  participationIsClosed: (state) => {
    if (!state.participationConfig?.closeDate) {
      return false
    }

    return dayjs().isAfter(state.participationConfig.closeDate, 'day')
  },
}

const actions = {
  /**
   * Get all active participation municipalities from the backend
   */
   async fetchParticipationConfigs({ commit }) {
     const { configs } = await fetch('/.netlify/functions/configs', {
       method: 'POST',
       body: JSON.stringify({}),
     })
      .then(await checkStatus)
      .then(returnJson)
      .catch(e => {
        Bugfender.error('fetchData ', e)
        throw e
      })

    commit('setParticipationConfigs', configs)
  },
  /**
   * Get a specific municipality config from the backend
   */
  async fetchParticipationConfig({ commit }, { participationSlug, previewCode }) {
    commit('setParticipationConfig', null)
    commit('setLoadingConfig', true)
    const { config } = await fetch('/.netlify/functions/configs', {
      method: 'POST',
      body: JSON.stringify({ participationSlug, previewCode }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .catch(e => {
        // Set the config to 'null' when the participation config could not be found
        if (e.message === 'Could not find participation config') {
          return { config: null }
        }

        Bugfender.error('fetchData ', e)
      })

    commit('setLoadingConfig', false)
    commit('setParticipationConfig', config)
  },
}

const mutations = {
  setParticipationConfigs(state, configs) {
    state.participationConfigs = configs
  },
  setParticipationConfig(state, config) {
    state.participationConfig = config
  },
  setLoadingConfig(state, isLoading) {
    state.isLoadingConfig = isLoading
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
