<template>
  <div class="LocatieInformatie__Attachments mb-2">
    <strong class="font-weight-bold">Bijlagen</strong>
    <template v-if="! isLoading">
      <Lightbox
        v-if="images.length > 0"
        :gallery="images"
      >
        <template #silentbox-item="{ item }">
          <span class="font-light">{{ item.description }}</span>
          <div class="ratio ratio-16x9">
            <div class="inner">
              <img
                :src="item.src"
                :alt="item.description"
              >
            </div>
          </div>
        </template>
      </Lightbox>
      <p
        v-else
        class="mb-0"
      >
        Geen bijlagen beschikbaar
      </p>
    </template>
    <p
      v-else
      class="mb-0"
    >
      Loading...
      <b-spinner
        small
        variant="primary"
        label="Spinning"
      />
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkStatus, returnJson } from '@/helpers/api'
import { Bugfender } from '@bugfender/sdk'
import { attachmentIDToDescription } from '@/../shared/valueholders/attachments'
import Lightbox from '@/components/common/lightbox/Lightbox.vue'

export default {
  name: 'ChargingpointAttachments',
  components: { Lightbox },
  props: {
    chargingpoint: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      images: [],
    }
  },
  computed: {
    ...mapGetters('config', ['participationConfig']),
  },
  async created() {
    await this.fetchAttachments()
  },
  methods: {
    async fetchAttachments() {
      this.isLoading = true

      this.images = []

      const { data } = await fetch('/.netlify/functions/fetch-views', {
        method: 'POST',
        body: JSON.stringify({
          code: this.participationConfig.municipalityCode,
          uuid: this.chargingpoint.uuid,
          id: this.chargingpoint.properties.id,
        }),
      })
        .then(await checkStatus)
        .then(returnJson)
        .catch(e => {
          Bugfender.error('fetch views: ', e)
          throw e
        })
        .finally(() => this.isLoading = false)

      if (data) {
        // create image url from BLOB
        for (const attachment of data) {
          const src = await fetch(attachment.downloadURL)
            .then(await checkStatus)
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))

          this.images.push({
            src,
            description: attachmentIDToDescription({ id: attachment.id }),
            filename: attachment.filename,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.LocatieInformatie {
  &__Attachments {
    padding-top: .5rem;
    border-top: 1px solid #CCCCCC !important;

    &__Image {
      width: 49.5%;
      position: relative;

      &__Inline {
        right: 7px;
      }

      .ratio {
        overflow: hidden;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
</style>
