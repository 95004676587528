<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    this.setTitle({
      title: 'Locatieplan',
    })
  },
  methods: {
    /**
     * Set the page title (used in browser tabs)
     */
    setTitle({ title }) {
      document.title = title
    },
  },
}
</script>

<style lang="scss">
html, body {
  height: 100%;
  font-family: 'Gibson' !important;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
html {
  overflow-y: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100%;
}
</style>
