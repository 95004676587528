<template>
  <main class="pageNotFound">
    <div class="pageNotFound__Code">
      404
    </div>
    <div
      class="pageNotFound__Message"
      v-text="message"
    />
    <b-button
      variant="primary"
      href="/"
    >
      {{ $t('notFound.homeButton') }}
    </b-button>
  </main>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.pageNotFound {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__Code {
    font-size: 3rem;
  }

  &__Message {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
}
</style>
