<template>
  <main class="Page--Home h-100 d-flex flex-column align-items-center justify-content-center">
    <b-card
      v-if="participationConfigs"
      tag="article"
      :img-src="image"
      img-alt="EVtools logo"
      class="mt-5"
      style="max-width: 300px;"
    >
      <b-card-text class="px-4 pb-4">
        <p
          class="mt-3 text-center"
          v-text="$t('chooseMunicipality')"
        />
        <div class="d-flex flex-column mt-4 align-items-center justify-content-center">
          <router-link
            v-for="{ slug, municipalityName } in participationConfigs"
            :key="slug"
            :to="{ name: 'Viewer', params: { participationSlug: slug } }"
            class="mt-3"
          >
            {{ municipalityName }}
          </router-link>
        </div>
      </b-card-text>
    </b-card>
  </main>
</template>

<script>
import { image } from '@/helpers/assets'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      image: image({ name: 'evtools.png' }),
    }
  },
  computed: {
    ...mapGetters('config', ['participationConfigs']),
  },
  created() {
    this.setTitle({ title: 'Locatieplan' })
    this.fetchParticipationConfigs()
  },
  methods: {
    ...mapActions('config', ['fetchParticipationConfigs']),
    /**
     * Set the page title (used in browser tabs)
     */
    setTitle({ title }) {
      document.title = title
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.Page--Home {
  .card {
    max-height: 85%;
    max-width: 330px;

    .card-img {
      background-color: map_get($theme-colors, "primary");
      padding: 0.5rem 0;
    }

    .card-body {
      overflow-y: auto;
    }
  }
}
</style>
