export default {
  title: 'Location plan - {municipality}',
  chooseMunicipality: 'Choose a municipality',
  continue: 'Continue',
  moreInfo: 'More information',
  chargingpoint: {
    status: {
      'unknown': 'Unknown',
      'alert': 'Point of focus',
      'charging-hub-definitive-private': 'Validated private charging hub',
      'charging-hub-definitive-semipublic': 'Validated semipublic charging hub',
      'charging-hub-definitive': 'Validated charging hub',
      'charging-hub-disabled-private': 'Disabled private charging hub',
      'charging-hub-disabled-semipublic': 'Disabled v charging hub',
      'charging-hub-disabled': 'Disabled charging hub',
      'charging-hub-in-progress-private': 'Private charging hub in progress',
      'charging-hub-in-progress-proprietary': 'Proprietary charging hub in progress',
      'charging-hub-in-progress-semipublic': 'Semipublic charging hub in progress',
      'charging-hub-in-progress': 'Charging hub in progress',
      'charging-hub-realized-private': 'Realized private charging hub',
      'charging-hub-realized-proprietary': 'Realized proprietary charging hub',
      'charging-hub-realized-semipublic': 'Realized semipublic charging hub',
      'charging-hub-realized': 'Realized charging hub',
      'charging-hub-rejected-private': 'Rejected private charging hub',
      'charging-hub-rejected-semipublic': 'Rejected semipublic charging hub',
      'charging-hub-rejected': 'Rejected charging hub',
      'charging-hub-request-private': 'Requested private charging hub',
      'charging-hub-request-semipublic': 'Requested semipublic charging hub',
      'charging-hub-request': 'Requested charging hub',
      'charging-hub-suggestion-private': 'Suggested private charging hub by citizen',
      'charging-hub-suggestion-proprietary': 'Suggested proprietary charging hub by citizen',
      'charging-hub-suggestion-semipublic': 'Suggested semipublic charging hub by citizen',
      'charging-hub-suggestion': 'Suggested charging hub by citizen',
      'definitive-private': 'Validated private charging point',
      'definitive-semipublic': 'Validated semipublic charging point',
      'definitive': 'Validated definitive charging point',
      'disabled-private': 'Disabled private charging point',
      'disabled-semipublic': 'Disabled semipublic charging point',
      'disabled': 'Disabled charging point',
      'fastcharger-definitive-private': 'Validated private fastcharger',
      'fastcharger-definitive-semipublic': 'Validated semipublic fastcharger',
      'fastcharger-definitive': 'Validated fastcharger',
      'fastcharger-disabled-private': 'Disabled private fastcharger',
      'fastcharger-disabled-semipublic': 'Disabled semipublic fastcharger',
      'fastcharger-disabled': 'Disabled fastcharger',
      'fastcharger-in-progress-private': 'Private fastcharger in progress',
      'fastcharger-in-progress-semipublic': 'Semipublic fastcharger in progress',
      'fastcharger-in-progress': 'Fastcharger in progress',
      'fastcharger-realized-private': 'Realized private fastcharger',
      'fastcharger-realized-semipublic': 'Realized semipublic fastcharger',
      'fastcharger-realized': 'Realized fastcharger',
      'fastcharger-rejected-private': 'Rejected private fastcharger',
      'fastcharger-rejected-semipublic': 'Rejected semipublic fastcharger',
      'fastcharger-rejected': 'Rejected fastcharger',
      'fastcharger-request-private': 'Requested private fastcharger',
      'fastcharger-request-semipublic': 'Requested semipublic fastcharger',
      'fastcharger-request': 'Requested fastcharger',
      'fastcharger-suggestion-private': 'Suggested private fastcharger by citizen',
      'fastcharger-suggestion-semipublic': 'Suggested semi fastcharger hub by citizen',
      'fastcharger-suggestion': 'Suggested fastcharger by citizen',
      'in-progress-private': 'Private charging point in progress',
      'in-progress-semipublic': 'Semipublic charging point in progress',
      'in-progress': 'Charging point in progress',
      'realized-private': 'Realized private charging point',
      'realized-semipublic': 'Realized semipublic charging point',
      'realized': 'Realized charging point',
      'rejected-private': 'Rejected private charging point',
      'rejected-semipublic': 'Rejected semi-public charging point',
      'rejected': 'Rejected charging point',
      'request-private': 'Requested private charging point',
      'request-semipublic': 'Requested semipublic charging point',
      'request': 'Requested charging point',
      'suggestion-private': 'Suggested private charging point',
      'suggestion-semipublic': 'Suggested semipublic charging point',
      'suggestion': 'Suggested charging point by citizen',
      'participation-suggestion-private': 'Suggested private charging point by citizen',
      'participation-suggestion-semipublic': 'Suggested semipublic charging point by citizen',
      'participation-suggestion': 'Suggest charging point by citizen',
    },
  },
  modal: {
    title: {
      policy: 'Policy rules',
    },
  },
  map: {
    search: 'Search address',
    newLocation: 'New location',
  },
  navbar: {
    title: 'Participation portal',
    titlePreview: 'Participation portal - Preview (opens on {openDate})',
    menuName: 'Menu',
    showPolicy: 'Show policy rules',
    showIntroductionModal: 'Show introduction',
    downloadMap: 'Download map',
    viewer: 'Viewer',
    viewerTitlePreview: 'Viewer - Preview (opens on {openDate})',
  },
  actionSidebar: {
    chargingpointID: 'Location {id}',
    informationAboutLocation: 'Information of the location',
    leaveAMessage: 'Leave a message',
    proposeNewLocation: 'Propose a new location',
    hasCommented: 'You\'ve already commented on this location.',
    disabledMessage: {
      realized: 'This charger is already installed and can thus not be commented on.',
      inProgress: 'This charger will soon be installed and can thus not be commented on anymore.',
      general: 'Comments for this location are disabeld.',
      interactionOnPastParticipations: 'Comments for this charging station are disabeld, because this location has already been participated.',
    },
    form: {
      feedbackPlaceholder: 'Your feedback',
      expiredCaptcha: 'The captcha puzzle has expired, please load again.',
      submitButton: {
        saving: 'Sending...',
        saveLocation: 'Suggest this location',
        locationSaved: 'Your location has been suggested!',
        saveComment: 'Save comment',
        commentSaved: 'Your comment has been saved!',
      },
    },
  },
  suggestionButton: {
    startSuggesting: 'Suggest a new location',
    stopSuggesting: 'Select a location on the map',
  },
  legend: {
    allLayers: 'All layers',
    openLegend: 'Open legend',
    closeLegend: 'Close legend',
  },
  downloadMap: {
    title: '{municipality} - Location plan for public chargers',
  },
  closed: {
    title: 'The time to respond to the proposal has elapsed',
    message: 'You can\'t comment anymore.',
  },
  notFound: {
    title: 'Not found (404)',
    message: 'Sorry, this page does not exist (anymore).',
    municipalityNotFound: 'This municipality does not have a viewer or a participation portal.',
    homeButton: 'Go home',
  },
}
