<template>
  <div class="jw-map-wrapper">
    <div ref="container" />
    <slot v-if="loaded" />
  </div>
</template>

<script>
/**
 * @copyright MIT
 * @author Wouter van Dam (wouter@journeyworks.nl)
 *
 * This is a basic, generic Vue wrapper component for MapBox.
 * This module is included in the source code of the application itself for simplicity and reliability
 */

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { mapGetters, mapMutations } from 'vuex'

/**
 * The Mapbox instance should not be reactive
 * TODO: This caused issues when two or more maps were used in a single application
 */

/**
 * A Generic MapBox wrapper component
 */
export default {
  name: 'MapBox',
  provide() {
    const self = this
    return {
      get mapbox() {
        return self.mapbox
      },
      get map() {
        return self.map
      },
    }
  },
  props: {
    accessToken: {
      type: String,
      required: true,
    },
    mapStyle: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      loaded: false,
      map: null,
      mapbox: null,
    }
  },
  ...mapGetters('app', ['inDevelopmentMode']),
  mounted() {
    //this.debugInDevMode('Mounting Mapbox')

    this.mapbox = mapboxgl
    this.mapbox.accessToken = this.accessToken

    this.map = new this.mapbox.Map(
      Object.assign({
        dragRotate: false,
      }, this.options, {
        container: this.$refs.container,
        style: this.mapStyle,
        preserveDrawingBuffer: true, // Enables export to png
      }),
    )
    // TODO: This solution is not reliable. Find a solution that captures 'empty' tiles
    // this.map.on('error', function() {
    //   // console.log('A MapBox error event occurred.');
    // });
    this.map.on('load', () => {
      //this.debugInDevMode('Mapbox Load event')

      this.loaded = true
      this.$emit('load', { map: this.map })
    })

    // broadcast if chargingpoint source is loaded which means our app is ready and user can interact with the layer
    this.map.on('idle', () => {
      if (this.map?.getSource('chargingpoints')) {
        this.setAppReady({ value: true })

        // deactivate event listener after first (initial) load
        this.map.off('idle', () => {})
      }
    })
  },
  beforeDestroy() {
    //this.debugInDevMode('Destroying Mapbox')

    this.$nextTick(() => {
      if (this.map) this.map.remove()
    })
  },
  methods: {
    ...mapMutations('app', [
      'setAppReady',
    ]),
    // debugInDevMode (text) {
    //   if (this.inDevelopmentMode) {
    //     /* eslint-disable-next-line no-console */
    //     console.log(text)
    //   }
    // },
  },
}
</script>

<style>
.jw-map-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
}

.mapboxgl-map {
  width: 100% !important;
  height: 100% !important;
}

</style>
