import packagejson from '../../package.json'

/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */

const state = {
  version: packagejson.version,
  ready: false,
  inDevelopmentMode: process.env.NODE_ENV?.toLowerCase() === 'development',
  showIntroductionModal: true,
  showPolicyModal: false,
}

const getters = {
  version: state => state.version,
  inDevelopmentMode: state => state.inDevelopmentMode,
  isAppReady: state => state.ready,
  showIntroductionModal: state => state.showIntroductionModal,
  showPolicyModal: state => state.showPolicyModal,
}

const actions = {
}

const mutations = {
  setAppReady(state, { value }) {
    state.ready = value
  },
  toggleIntroductionModal(state) {
    state.showIntroductionModal = ! state.showIntroductionModal
  },
  togglePolicyModal(state) {
    state.showPolicyModal = ! state.showPolicyModal
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
