import { checkStatus, returnJson } from '@/helpers/api'
import { Bugfender } from '@bugfender/sdk'

/**
 * Declare Variable
 */
const state = {
  // Whether the map is done loading
  loaded: false,

  // Charging locations
  locations: [],
  selectedChargingPointRefId: null,
  suggestedChargingpoint: null,
  isSuggestionModeActive: false,
}

const getters = {
  isLoaded: state => state.loaded,

  isChargingpointSelected: state => !!(state.selectedChargingPointRefId ?? state.suggestedChargingpoint?.coordinates),
  getSelectedChargingpoint: state => state.locations.find(({ ref }) => ref['@ref'].id === state.selectedChargingPointRefId),
  getSuggestedChargingpoint: state => state.suggestedChargingpoint,
  getChargingPointByRefId: state => ({ refId }) => state.locations.find(({ ref }) => ref['@ref'].id === refId),
  hasSuggestions: state => state.locations.find((location) => location.properties?.status === 'suggestion'),
  isSuggestionModeActive: state => state.isSuggestionModeActive,
  //
  getLocations: state => state.locations,
}
const actions = {
  async getChargingLocations({ commit, rootState: { config } }, { previewCode }) {
    const { municipalityCode } = config.participationConfig
    const { chargingpoints } = await fetch('/.netlify/functions/chargingpoints', {
      method: 'POST',
      body: JSON.stringify({ municipalityCode, previewCode }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .catch(e => {
        Bugfender.error('fetchData ', e)
        throw e
      })

    if (chargingpoints) {
      commit('setChargingLocations', {
        locations: chargingpoints,
      })
    }
  },
  selectChargingPoint({ commit }, { refId }) {
    commit('setSelectedChargingPoint', { refId })
  },
  deselectChargingPoint({ commit }) {
    commit('setSelectedChargingPoint', { refId: null })
  },
  setSuggestedChargingPoint({ commit }, { coordinates }) {
    commit('setSuggestedChargingPoint', { coordinates })
    commit('setSuggestionModeState', { active: false })
  },
  resetSuggestedChargingPoint({ commit }) {
    commit('setSuggestedChargingPoint', { coordinates: null })
  },
  toggleSuggestionModeState({ commit, state }) {
    commit('setSuggestionModeState', { active: !state.isSuggestionModeActive })
    commit('setSuggestedChargingPoint', { coordinates: null })
    if (state.isSuggestionModeActive) {
      commit('setSelectedChargingPoint', { refId: null })
    }
  },
}
const mutations = {
  setChargingLocations(state, { locations }) {
    state.locations = locations
      .filter(location => !location.data.deleted_at || location.data.deleted_at === null)
      .map(location => Object.assign(location.data, { ref: location.ref }))
  },
  addChargingLocation(state, { location }) {
    const newLocation = Object.assign(location.data, { ref: location.ref })
    state.locations = [...state.locations, newLocation]
  },
  setSelectedChargingPoint(state, { refId }) {
    state.selectedChargingPointRefId = refId
  },
  setSuggestedChargingPoint(state, { coordinates }) {
    if (!coordinates) {
      state.suggestedChargingpoint = null
      return
    }

    state.suggestedChargingpoint = {
      coordinates,
      properties: {
        status: 'suggestion',
      },
    }
  },
  setLoadedStatus(state, { status }) {
    state.loaded = status
  },
  setSuggestionModeState(state, { active }) {
    state.isSuggestionModeActive = !!active
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
