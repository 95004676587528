<template>
  <div class="SidebarWrapper">
    <transition
      name="sidebar"
      mode="out-in"
    >
      <div
        v-if="isChargingpointSelected"
        class="Sidebar"
      >
        <div class="SidebarContent">
          <h3 v-text="titleText" />

          <!-- SIDEBAR IMAGES -->

          <ChargingpointAttachments :chargingpoint="chargingpoint" />

          <!-- SIDEBAR INFO -->

          <div
            v-if="chargingpoint.uuid"
            class="SidebarInfo border-top"
          >
            <div
              class="font-weight-bold"
              v-text="$t('actionSidebar.chargingpointID', { id: chargingpoint.properties.id })"
            />
            <div v-if="chargingpoint.address.simple_address">
              {{ chargingpoint.address.simple_address }}
            </div>
            <div v-else>
              {{ chargingpoint.address.street }}
              <span v-if="chargingpoint.address.number">{{ chargingpoint.address.number }}</span>
            </div>
            <div>
              <span v-text="chargingpoint.address.postalcode" />
              <span
                class="ml-2"
                v-text="chargingpoint.address.city"
              />
            </div>
            <!-- If we would need to see tha phase the charging point is in -->
            <!-- <div v-if="chargingpoint.phase">
              <span> Fase {{ getPhase(chargingpoint.phase) }}</span>
            </div> -->
          </div>

          <!-- SIDEBAR MESSAGES -->

          <div>
            <div
              v-if="!isInteractive || !suggestedChargingpoint && !['definitive', 'fastcharger-definitive', 'charging-hub-definitive'].includes(chargingpoint.properties.status)"
              class="SidebarMessage border-top"
              v-text="disabledCommentsMessage"
            />
            <div
              v-else-if="!hasInteractionOnPastParticipations && hasAlreadyParticipated"
              class="SidebarMessage border-top"
              v-text="messageInteraction"
            />
            <div
              v-else-if="hasAlreadyCommented"
              class="SidebarMessage border-top"
              v-text="$t('actionSidebar.hasCommented')"
            />
            <div
              v-else
              class="border-top pt-3"
            >
              <SidebarForm />
            </div>
          </div>
          <div
            class="Sidebar__Close"
            @click="closeSidebar"
          >
            <BIconX />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SidebarForm from '@/components/map/sidebar/SidebarForm'

import { mapGetters } from 'vuex'
import { BIconX } from 'bootstrap-vue'
import { EventBus } from '@/services/eventbus'
import ChargingpointAttachments from '@/components/common/ChargingpointAttachments.vue'

export default {
  name: 'Sidebar',
  components: {
    ChargingpointAttachments,
    SidebarForm,
    BIconX,
  },
  computed: {
    ...mapGetters('config', ['participationConfig', 'isInteractive', 'isInteractiveOnPastParticipations']),
    ...mapGetters('map', ['isChargingpointSelected', 'getSuggestedChargingpoint', 'getSelectedChargingpoint']),
    chargingpoint () {
      return this.getSuggestedChargingpoint ?? this.getSelectedChargingpoint
    },

    suggestedChargingpoint () {
      return this.getSuggestedChargingpoint
    },

    titleText () {
      if (!this.isInteractive || !this.suggestedChargingpoint && !['definitive', 'fastcharger-definitive',  'charging-hub-definitive'].includes(this.chargingpoint.properties.status)) {
        return this.$t('actionSidebar.informationAboutLocation')
      }
      if (this.chargingpoint.uuid) {
        return this.$t('actionSidebar.leaveAMessage')
      }

      return this.$t('actionSidebar.proposeNewLocation')
    },
    configPhases () {
      return this.participationConfig?.configPhases
    },
    emailSubject () {
      return encodeURIComponent(`Participatieportaal - Locatie ${this.chargingpoint.properties.id}`)
    },
    hasAlreadyCommented() {
      if (!this.chargingpoint.uuid && this.isInteractive) {
        return false
      }

      return !!localStorage.getItem(`has-commented-on-${this.chargingpoint.uuid}`)
    },
    hasAlreadyParticipated(){
      return this.getDateInPast()
    },
    hasInteractionOnPastParticipations(){
      return this.isInteractive && this.isInteractiveOnPastParticipations
    },
    disabledCommentsMessage () {
      if (!this.isInteractive || !this.chargingpoint.properties.status) {
        return this.$t('actionSidebar.disabledMessage.general')
      }

      const messages = {
        realized: this.$t('actionSidebar.disabledMessage.realized'),
        'in-progress': this.$t('actionSidebar.disabledMessage.inProgress'),
      }
      return messages[this.chargingpoint.properties.status]
    },
    messageInteraction(){
        return this.$t('actionSidebar.disabledMessage.interactionOnPastParticipations')
    },
    cpo () {
      return this.getSelectedChargingpoint.properties.cpo // uuid
    },
    defaultSidebarText() {
      if (!this.isInteractive) return
        const defaultCpo = this.participationConfig.cpos.find(cpo => cpo.value === 'default')
        return defaultCpo ? defaultCpo.sidebarText : this.isInteractive ? this.participationConfig.sidebarText : ''
    },
    cpoSidebarText () {
      if (!this.cpo && this.isInteractive) {
        return this.defaultSidebarText || this.$t('actionSidebar.disabledMessage.noCpo')
      }

      return this.participationConfig.cpos.find(cpo => (cpo.value || cpo) === this.cpo)?.sidebarText || this.defaultSidebarText
    },
  },
  methods: {
    getPhase (currentPhase) {
      return typeof currentPhase === 'number'
        ? currentPhase
        : this.configPhases.find(phase => phase.uuid === currentPhase)?.label
    },
    closeSidebar() {
      EventBus.$emit('deselect-chargingpoint')
    },
    getDateInPast(){
      const reversedDatesArray = this.chargingpoint.participation.closingDates.slice().reverse()
      let pastDate = false

      for (const date in reversedDatesArray) {
        if (new Date(reversedDatesArray[date]) > new Date()){
          continue
        } else {
          pastDate = true
          break
        }
      }
      return pastDate
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

$container-width: 340px;
$container-height: 380px;

.SidebarWrapper {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 900px) {
    left: 0;
    top: auto;
  }

  .Sidebar {
    width: $container-width;
    height: 100%;
    background: white;
    transition: margin-right 0.25s ease-out;
    overflow-y: auto;
    margin-bottom: 0;

    @media (max-width: 900px) {
      transition: margin-bottom 0.25s ease-out;
      height: $container-height;
      max-height: 40vh;
      width: 100%;
    }

    &__Close {
      position: absolute;
      z-index: 3;
      top: 0.7rem;
      right: 0.7rem;
      font-size: 18px;
      cursor: pointer;
    }

    .SidebarContent {
      padding: 1rem;

      a {
        text-decoration: underline;

        &:hover {
          color: black;
        }
      }

      .SidebarInfo,
      .SidebarMessage {
        padding: 0.5rem 0;
      }

      .submitButton {
        margin-top: 1rem;
      }
    }
  }
}

.sidebar-enter,
.sidebar-leave-to {
  @media (max-width: 900px) {
    margin-bottom: - $container-height !important;
  }
  @media (min-width: 900px) {
    margin-right: - $container-width !important;
  }
}
</style>
