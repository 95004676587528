import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false


// Bugfender
//import { Bugfender } from '@bugfender/sdk'
// Bugfender.init({
//   appKey: process.env.VUE_APP_BUGFENDER_API_KEY,
//   build: process.env.VUE_APP_BUGFENDER_BUILD || 'none',
// })


// Internationalization
import VueI18n from 'vue-i18n'
import messages from './translations/index'


// Fonts (Gibson)
import '@/assets/sass/fonts.scss'


// Bootstrap config
import '@/assets/sass/bootstrap.scss'


// Bootstrap
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)


// Initialize I18N
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') ?? 'nl',
  fallbackLocale: 'en',
  messages,
})


new Vue({
  store,
  router,
  render: h => h(App),
  i18n,
}).$mount('#app')
