<template>
  <MapBoxPopup
    :show="!!coordinates"
    :coordinates="coordinates"
    :offset="[0,-15]"
    @close="handleClose"
  >
    <div v-text="$t('map.newLocation')" />
  </MapBoxPopup>
</template>

<script>
import MapBoxPopup from '@/components/map/modals/MapBoxPopup'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChargerEditorPopup',
  components: { MapBoxPopup },
  data() {
    return {
      isHoveringChargingpoint: false,
    }
  },
  computed: {
    ...mapGetters('map', [
      'getSuggestedChargingpoint',
      'isSuggestionModeActive',
    ]),
    coordinates() {
      return this.getSuggestedChargingpoint?.coordinates ?? null
    },
  },
  created: async function(){
    this.$store.map.on('click', this.handleAddMarkerEvent)

    // Cursor
    this.$store.map.on('mousemove', this.handleMouseMoveEvent)
    this.$store.map.on('mouseenter', 'chargingpoints', this.handleHoverChargingpoint)
    this.$store.map.on('mouseleave', 'chargingpoints', this.handleHoverChargingpoint)
  },
  beforeDestroy() {
    this.$store.map.off('click', this.handleAddMarkerEvent)

    // Cursor
    this.$store.map.off('mousemove', this.handleMouseMoveEvent)
    this.$store.map.off('mouseenter', 'chargingpoints', this.handleHoverChargingpoint)
    this.$store.map.off('mouseleave', 'chargingpoints', this.handleHoverChargingpoint)
  },
  methods: {
    ...mapActions('map', [
      'setSuggestedChargingPoint',
      'resetSuggestedChargingPoint',
    ]),
    handleHoverChargingpoint({ type }) {
      this.isHoveringChargingpoint = type === 'mouseenter'
    },
    handleMouseMoveEvent() {
      let style = this.$store.map.getCanvas().style
      if (this.isSuggestionModeActive) {
        style.cursor = 'crosshair'
        return
      }

      if (this.isHoveringChargingpoint) {
        style.cursor = 'pointer'
        return
      }

      style.cursor = ''
    },
    handleClose() {
      this.resetSuggestedChargingPoint()
    },
    handleAddMarkerEvent(e) {
      if (!e._defaultPrevented && this.isSuggestionModeActive) {
        this.setSuggestedChargingPoint({ coordinates: e.lngLat.toArray() })
        this.$store.map.getCanvas().style.cursor = ''
      }
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>
