<template>
  <div class="Page--Viewer Viewer h-100 d-flex flex-column flex-grow-1">
    <NavBar />
    <main
      v-if="participationConfig || isLoadingConfig"
      class="Viewer__Main d-flex flex-grow-1 overflow-hidden"
    >
      <div
        v-if="participationConfig"
        class="Viewer__Content d-flex position-relative flex-grow-1 flex-shrink-0"
      >
        <Map />
        <ClosedNotice v-if="participationIsClosed && participationConfig.showMessageAfterClose" />
        <ChargerPointsLayer v-else />
      </div>
    </main>
    <NotFound
      v-else
      :message="$t('notFound.municipalityNotFound')"
    />
    <IntroductionModal v-if="participationConfig && !participationIsClosed" />
    <PolicyModal v-if="participationConfig" />
  </div>
</template>

<script>
/**
 * This component mainly takes care of the (responsive) layout
 */
import NotFound from '@/components/NotFound'
import NavBar from '@/components/NavBar'
import IntroductionModal from '@/components/IntroductionModal'
import PolicyModal from '@/components/PolicyModal'
import Map from '@/components/map/Map'
import ChargerPointsLayer from '@/components/map/ChargerPointsLayer'
import ClosedNotice from '@/components/ClosedNotice'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Viewer',
  components: {
    NotFound,
    NavBar,
    IntroductionModal,
    PolicyModal,
    Map,
    ChargerPointsLayer,
    ClosedNotice,
  },
  computed: {
    ...mapGetters('config', [
      'isLoadingConfig',
      'participationConfig',
      'participationIsClosed',
    ]),
  },
  watch: {
    participationConfig: {
      deep: true,
      immediate: true,
      handler (participationConfig) {
        if (participationConfig === null) {
          return
        }

        // Return to the home page if its not active and no closed message should be displayed
        if (
          !this.$route.query.preview &&
          this.participationIsClosed &&
          !this.participationConfig.showMessageAfterClose
        ) {
          return this.$router.push({ name: 'Home' })
        }

        this.setTitle({
          title: this.$t('title', { municipality: participationConfig.municipalityName }),
        })
      },
    },
  },
  created() {
    const participationSlug = this.$route.params.participationSlug
    const previewCode = this.$route.query.preview
    this.fetchParticipationConfig({ participationSlug, previewCode })
  },
  methods: {
    ...mapActions('config', ['fetchParticipationConfig']),
    setTitle({ title }) {
      document.title = title
    },
  },
}
</script>

<style lang="scss">
.Viewer {
  &__Content {
    width: calc(100% - 500px);
  }
  &__Main {
    overflow-y: scroll;
  }

  @media (max-height: 600px) {
    &__Content {
      width: 50%;
    }
  }

  @media (max-width: 600px) {
    &__Main {
      flex-direction: column-reverse;
    }
    &__Content {
      width: 100%;
    }
    &__Content {
      height: 60vh;
      min-height: 60vh;
      box-shadow: 0px 1px 0px 0px rgba(223,226,229,1);
    }
  }
}

</style>
