<template>
  <NotFound
    title="404"
    :message="$t('notFound.message')"
  />
</template>

<script>
import NotFound from '@/components/NotFound'

export default {
  components: {
    NotFound,
  },
  created() {
    document.title = this.$t('notFound.title')
  },
}
</script>
