<template>
  <div
    class="MunicipalityLogo"
    :style="`background: ${participationConfig.logoBackgroundColor}; height: ${logoHeight}`"
  >
    <Logo
      :organisation="participationConfig.municipalityName"
      :image="`logos/${participationConfig.slug}.png`"
    />
  </div>
</template>

<script>
import Logo from '@/components/Logo'

import { mapGetters } from 'vuex'

export default {
  components: {
    Logo,
  },
  props: {
    logoHeight: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('config', ['participationConfig']),
  },
}
</script>

<style lang="scss">
.MunicipalityLogo {
  position: absolute;
  bottom: 0.7rem;
  right: 0.7rem;
  height: 4rem;
  max-width: 16rem;
  padding: 0.7rem;
  background-color: white;
  opacity: 0.9;
  border-radius: 0.25rem;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1));

  @media (min-width: 600px) {
    bottom: 2rem;
    height: 5rem;
    max-width: 20rem;
  }
}
</style>
