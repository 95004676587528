<template>
  <b-modal
    v-model="show"
    :ok-title="$t('continue')"
    ok-only
    scrollable
    centered
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="participationConfig.introductionText" />
    <!--eslint-enable-->
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('config', [
      'participationConfig',
    ]),
    ...mapGetters('app', [
      'showIntroductionModal',
    ]),
    show: {
      get() {
        return this.showIntroductionModal
      },
      set() {
        this.toggleIntroductionModal()
      },
    },
  },
  methods: {
    ...mapMutations('app', [
      'toggleIntroductionModal',
    ]),
  },
}
</script>

<style lang="scss">
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  @media (min-width: 600px) {
    max-height: 75vh !important;
  }
}
</style>
