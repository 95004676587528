<template>
  <b-modal
    v-model="show"
    :title="$t('modal.title.policy')"
    :ok-title="$t('continue')"
    ok-only
    scrollable
    centered
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="participationConfig.policyText" />
    <!--eslint-enable-->
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('config', [
      'participationConfig',
    ]),
    ...mapGetters('app', [
      'showPolicyModal',
    ]),
    show: {
      get() {
        return this.showPolicyModal
      },
      set() {
        this.togglePolicyModal()
      },
    },
  },
  methods: {
    ...mapMutations('app', [
      'togglePolicyModal',
    ]),
  },
}
</script>

<style lang="scss">
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  @media (min-width: 600px) {
    max-height: 75vh !important;
  }
}
</style>
